const platformVisualisations = document.querySelectorAll<HTMLElement>(
    '.platform-visualisation'
)

platformVisualisations.forEach((visual) => {
    const buttons = visual.querySelectorAll<HTMLElement>(
        '[data-target-step-id]'
    )
    const cards = visual.querySelectorAll<HTMLElement>('[data-step-id]')
    const images = visual.querySelectorAll<HTMLElement>('[data-step-image-id]')

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            const stepId = button.dataset.targetStepId

            buttons.forEach(
                (button) =>
                    (button.ariaSelected = `${
                        button.dataset.targetStepId === stepId
                    }`)
            )

            cards.forEach((card) => {
                card.ariaHidden = `${card.dataset.stepId !== stepId}`
            })
            images.forEach((image) => {
                image.classList.toggle(
                    'active',
                    image.dataset.stepImageId === stepId
                )
            })
        })
    })
})
