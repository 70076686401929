import Details from '../../modules/details'

document.querySelectorAll('.step--details').forEach((stepItem) => {
    new Details(stepItem as HTMLDetailsElement)

    if (stepItem.classList.contains('step--active')) {
        setTimeout(() => {
            stepItem.scrollIntoView({
                behavior: 'smooth',
            })
        }, 100)
    }
})
