const ticker = document.querySelector<HTMLElement>('.usp-ticker')
const tickerItems = ticker?.querySelectorAll<HTMLElement>('.usp-ticker__item')

if (tickerItems?.length && ticker) {
    let index = 0

    setInterval(() => {
        if(index === tickerItems.length - 1) {
            index = -1;
        }
        index++;
        const positionOfTickerItem = tickerItems[index].offsetTop

        ticker?.scrollTo({
            top: positionOfTickerItem,
            behavior: 'smooth',
        })
    }, 3000);
}