import {QUOTATION_CLASSES} from './quotation'

QUOTATION_CLASSES.forEach((quotationClass) => {
    const quotation = document.querySelector(`.${quotationClass}`)

    if (!quotation) return

    const QUOTATION_NO_SCROLL_CLASS = `${quotationClass}--no-scoll`

    const SUMMARY_CLASS = 'summary'
    const SUMMARY_OPEN_CLASS = `${SUMMARY_CLASS}--open`

    const SUMMARY_TOGGLE_CLASS = 'summary__toggle'
    const SUMMARY_CLOSE_CLASS = 'summary__close'
    const summary = quotation.querySelector(`.${SUMMARY_CLASS}`) as HTMLElement
    const summaryToggle = quotation.querySelector(
        `.${SUMMARY_TOGGLE_CLASS}`
    ) as HTMLButtonElement

    summaryToggle.addEventListener('click', () => {
        ;(quotation as Element).classList.add(QUOTATION_NO_SCROLL_CLASS)
        summary.classList.add(SUMMARY_OPEN_CLASS)
    })

    const summaryClose = summary.querySelector(
        `.${SUMMARY_CLOSE_CLASS}`
    ) as HTMLButtonElement

    function closeSummary() {
        ;(quotation as HTMLElement).classList.remove(QUOTATION_NO_SCROLL_CLASS)
        summary.classList.remove(SUMMARY_OPEN_CLASS)
    }

    summaryClose.addEventListener('click', closeSummary)
    document.addEventListener('keyup', (e) =>
        e.code === 'Escape' ? closeSummary() : false
    )
})
