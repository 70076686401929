const bnrPlayerElements = document.querySelectorAll('.bnr-player')

bnrPlayerElements.forEach((bnrPlayerElement) => {
    const bnrPlayerPlaceholder = bnrPlayerElement.querySelector(
        '.bnr-player__placeholder'
    ) as HTMLElement
    const bnrPlayerPlaceholderButton = bnrPlayerPlaceholder.querySelector(
        '.bnr-player__placeholder-button'
    ) as HTMLButtonElement

    bnrPlayerPlaceholderButton.addEventListener('click', () => {
        // @ts-ignore
        Cookiebot.show()
    })
})

if (bnrPlayerElements.length) {
    window.addEventListener('CookiebotOnAccept', () => {
        // @ts-ignore
        if (!Cookiebot.consent.marketing) {
            removeBnrPlayers()

            return
        }

        createBnrPlayers()
    })

    window.addEventListener('CookiebotOnDecline', () => removeBnrPlayers())
}

function removeBnrPlayers() {
    bnrPlayerElements.forEach((bnrPlayerElement) => {
        const bnrPlayerIframe = bnrPlayerElement.querySelector(
            '.bnr-player__iframe'
        ) as HTMLIFrameElement

        if (!bnrPlayerIframe) return

        bnrPlayerIframe.remove()
    })
}

function createBnrPlayers() {
    bnrPlayerElements.forEach((bnrPlayerElement) => {
        const bnrPlayerInner = bnrPlayerElement.querySelector(
            '.bnr-player__inner'
        ) as HTMLElement
        const bnrPlayerPlaceholder = bnrPlayerElement.querySelector(
            '.bnr-player__placeholder'
        ) as HTMLElement
        const bnrPlayerIframeString = (bnrPlayerElement as HTMLElement).dataset
            .playerHtml

        if (!bnrPlayerIframeString) return

        const playerTemplate = document.createElement(
            'template'
        ) as HTMLTemplateElement

        playerTemplate.innerHTML = bnrPlayerIframeString.trim()

        const bnrPlayerIframe = playerTemplate.content.firstChild

        if (!bnrPlayerIframe) return

        bnrPlayerInner.insertBefore(bnrPlayerIframe, bnrPlayerPlaceholder)
    })
}
