import AirDatepicker, {
    AirDatepickerDate,
    AirDatepickerLocale,
    AirDatepickerPosition,
    AirDatepickerViewsSingle,
} from 'air-datepicker'
import locale from 'air-datepicker/locale/nl'
import 'air-datepicker/air-datepicker.css'

const QUOTATION_SECONDARY_CLASS = 'quotation--secondary'
const DELIVERY_RETRIEVAL_WRAPPER_CLASS = 'delivery-retrieval__wrapper'
const DELIVERY_POSTAL_FIELD_CLASS = 'delivery-retrieval__postal-code'
const DELIVERY_DATE_FIELD_CLASS = 'delivery-retrieval__delivery-date'
const RETRIEVAL_OPTION_CLASS = 'deliver-retrieval__input'
const RETRIEVAL_WRAPPER_CLASS = 'delivery-retrieval__retrieval'
const RETRIEVAL_DATE_FIELD_CLASS = 'delivery-retrieval__retrieval-date'

const deliveryRetrievalWrapper = document.querySelector<HTMLElement>(
    `.${DELIVERY_RETRIEVAL_WRAPPER_CLASS}`
)

if (deliveryRetrievalWrapper) {
    const unavailableDates = deliveryRetrievalWrapper.dataset.unavailableDates
        ? deliveryRetrievalWrapper.dataset.unavailableDates.split(', ')
        : false

    const postalCodeField = document.querySelector(
        `.${DELIVERY_POSTAL_FIELD_CLASS}`
    ) as HTMLInputElement
    const deliveryDateField = document.querySelector(
        `.${DELIVERY_DATE_FIELD_CLASS}`
    ) as HTMLInputElement
    const retrievalDateField = document.querySelector(
        `.${RETRIEVAL_DATE_FIELD_CLASS}`
    ) as HTMLInputElement
    let disabledWeekdays = [0, 6]
    let classes = ''
    let initialSetup = true
    const deliveryStartDate = getDeliveryStartDate()

    if (document.querySelector(`.${QUOTATION_SECONDARY_CLASS}`)) {
        classes += 'air-datepicker--secondary'
    }

    const defaultDatePickerValues: {
        locale: AirDatepickerLocale
        classes: string
        position: AirDatepickerPosition
        firstDay: number
    } = {
        locale,
        classes,
        position: 'bottom left',
        firstDay: 1,
    }

    // Delivery date
    const deliveryDatePicker = new AirDatepicker(deliveryDateField, {
        ...defaultDatePickerValues,
        startDate: deliveryStartDate,
        onRenderCell: ({date, cellType}) =>
            onRenderCellDisableDays(date, cellType, deliveryStartDate),
        onSelect() {
            if (initialSetup) {
                initialSetup = false

                return
            }

            retrievalDatePicker.clear()
            updateRetrievalStartDate()
        },
    })

    // Retrieval date
    let retrievalStartDate = getRetrievalStartDate()

    const retrievalDatePicker = new AirDatepicker(retrievalDateField, {
        ...defaultDatePickerValues,
        startDate: retrievalStartDate,
        onRenderCell: ({date, cellType}) =>
            onRenderCellDisableDays(date, cellType, retrievalStartDate),
    })

    function onRenderCellDisableDays(
        date: Date,
        cellType: AirDatepickerViewsSingle,
        startDate: Date
    ): {disabled: boolean} | void {
        if (cellType === 'day') {
            const isBeforeStart = date < startDate
            const isUnavailable = unavailableDates
                ? unavailableDates.some(
                      (unavailableDate) =>
                          dateToIsoDate(date) === unavailableDate
                  )
                : false

            if (isBeforeStart || isDisabledDayOfWeek(date) || isUnavailable) {
                return {
                    disabled: true,
                }
            }
        }
    }
    function getDateFromDateFieldValue(value): Date {
        let splitDate = value.split('-')

        const day = Number(splitDate[0])
        const month = Number(splitDate[1]) - 1
        const year = Number(splitDate[2])

        return new Date(year, month, day)
    }
    function getDeliveryStartDate(): Date {
        const today = new Date()
        let earliestDeliveryDate = addDay(new Date(today.getTime()))

        // Skip past weekends and disabled days
        while (
            isUnavailableDate(earliestDeliveryDate) ||
            isDisabledDayOfWeek(earliestDeliveryDate)
        ) {
            earliestDeliveryDate = addDay(earliestDeliveryDate)
        }

        // Skip past the next business day
        let businessDaysSkipped = 0
        while (businessDaysSkipped < 1) {
            earliestDeliveryDate = addDay(earliestDeliveryDate)
            if (
                !isUnavailableDate(earliestDeliveryDate) &&
                !isDisabledDayOfWeek(earliestDeliveryDate)
            ) {
                businessDaysSkipped++
            }
        }

        return setToMidnight(earliestDeliveryDate)
    }

    function setToMidnight(date: Date): Date {
        const midnightDate = new Date(date.getTime())
        midnightDate.setHours(0, 0, 0, 0)

        return midnightDate
    }

    function addDay(date: Date): Date {
        return new Date(date.setDate(date.getDate() + 1))
    }

    function isUnavailableDate(date: Date): boolean {
        return unavailableDates
            ? unavailableDates.includes(dateToIsoDate(date))
            : false
    }

    function isDisabledDayOfWeek(date: Date): boolean {
        const dayOfWeek = date.getDay()
        return disabledWeekdays.includes(dayOfWeek)
    }

    function getRetrievalStartDate(): Date {
        let retrievalStartDate = getDeliveryStartDate()

        if (deliveryDateField.value) {
            retrievalStartDate = getDateFromDateFieldValue(
                deliveryDateField.value
            )
        }

        return addDay(setToMidnight(retrievalStartDate))
    }
    function updateRetrievalStartDate(): void {
        let retrievalStartDate = getRetrievalStartDate()

        retrievalDatePicker.update({
            startDate: retrievalStartDate,
            onRenderCell: ({date, cellType}) =>
                onRenderCellDisableDays(date, cellType, retrievalStartDate),
        })
    }
    function getNumbersFromPostalCode(postalCode: string): string | false {
        const postalCodeMatch = postalCode.match(/^([0-9]{4})\s?[a-zA-Z]{2}$/)

        return postalCodeMatch ? postalCodeMatch[1] : false
    }
    function setAvailableWeekdays(postalCode: string): void {
        const postalCodeNumbers = getNumbersFromPostalCode(postalCode)

        if (postalCodeNumbers === false) {
            return
        }

        const BASE_URL = window.location.origin

        fetch(`${BASE_URL}/postal-code-ranges/${postalCodeNumbers}/`)
            .then((response) => response.json())
            .then(({data}) => {
                if (data[0]) {
                    let deliveryDays = data[0].deliveryDays

                    deliveryDays = Object.keys(deliveryDays)
                        .map((key, index) =>
                            !deliveryDays[key] ? index + 1 : false
                        )
                        .filter((value) => value !== false)

                    disabledWeekdays = [0, ...deliveryDays, 6]
                } else {
                    disabledWeekdays = [0, 6]
                }

                if (initialSetup) {
                    if (deliveryDateField.value) {
                        deliveryDatePicker.update({
                            selectedDates: [
                                getDateFromDateFieldValue(
                                    deliveryDateField.value
                                ),
                            ],
                        })
                    }
                    if (retrievalDateField.value) {
                        retrievalDatePicker.update({
                            selectedDates: [
                                getDateFromDateFieldValue(
                                    retrievalDateField.value
                                ),
                            ],
                        })
                    }
                } else {
                    deliveryDatePicker.clear()
                    retrievalDatePicker.clear()
                }
            })
    }
    /**
     * This function transforms dateObject to the ISO date but includes our timezone offset while doing so.
     * @param date Date object or timestamp
     */
    function dateToIsoDate(date: Date | number): string {
        const dateObject = new Date(date)
        const timezoneOffset = dateObject.getTimezoneOffset() * 60000
        const isoDate = new Date(dateObject.getTime() - timezoneOffset)
            .toISOString()
            .split('T')[0]

        return isoDate
    }

    if (postalCodeField.value) {
        setAvailableWeekdays(postalCodeField.value)
    } else {
        initialSetup = false
    }

    postalCodeField.addEventListener('keyup', () =>
        setAvailableWeekdays(postalCodeField.value)
    )

    const retrievalOptions = document.querySelectorAll(
        `.${RETRIEVAL_OPTION_CLASS}`
    )
    const retrievalWrapper = document.querySelector(
        `.${RETRIEVAL_WRAPPER_CLASS}`
    ) as HTMLElement

    retrievalOptions.forEach((option) => {
        option.addEventListener('click', () => {
            const showRetrievalDate = (option as HTMLInputElement).dataset
                .showRetrievalDate

            if (showRetrievalDate === '1') {
                retrievalWrapper.classList.remove(
                    `${RETRIEVAL_WRAPPER_CLASS}--hidden`
                )
            } else {
                retrievalWrapper.classList.add(
                    `${RETRIEVAL_WRAPPER_CLASS}--hidden`
                )
                retrievalDatePicker.clear()
            }
        })
    })
}
