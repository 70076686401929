const scrollButtons = document.querySelectorAll('[data-scroll-to]')

scrollButtons.forEach((scrollButton) => {
    scrollButton.addEventListener('click', () => {
        const anchor = document.querySelector(
            `#${scrollButton.getAttribute('data-scroll-to')}`
        )

        anchor?.scrollIntoView({
            behavior: 'smooth',
        })
    })
})
