const NAVIGATION_CLASS = 'navigation'
const NAVIGATION_OPEN_CLASS = `${NAVIGATION_CLASS}--open`
const DROPDOWN_CLASS = 'dropdown'
const DROPDOWN_OPEN_CLASS = `${DROPDOWN_CLASS}--open`
const DROPDOWN_ENABLE_HOVER_CLASS = `${DROPDOWN_CLASS}--enable-hover`
const DROPDOWN_CONTENT_CLASS = 'dropdown__content'
const BODY_SCROLL_LOCK_CLASS = 'scroll-lock'

const navigation = document.querySelector(`.${NAVIGATION_CLASS}`) as HTMLElement
const stickyButtonWrapper = document.querySelector(
    '.navigation__sticky-wrapper'
) as HTMLElement

if (navigation) {
    const navigationDropdownsWrapper = navigation.querySelector(
        '.navigation__dropdowns'
    ) as HTMLElement
    const navigationDropdowns = navigation.querySelectorAll(
        `.${DROPDOWN_CLASS}`
    )
    const navigationToggle = navigation.querySelector(
        '.navigation__toggle'
    ) as HTMLElement

    function closeOpenDropdowns() {
        navigationDropdowns.forEach((dropdown) =>
            dropdown.classList.remove(DROPDOWN_OPEN_CLASS)
        )
    }

    navigationDropdowns.forEach((dropdown) => {
        const dropdownLabel = dropdown.querySelector(
            '.dropdown__label'
        ) as HTMLElement
        const dropdownBack = dropdown.querySelector(
            '.dropdown__back'
        ) as HTMLElement
        const dropdownContent = dropdown.querySelector(
            `.${DROPDOWN_CONTENT_CLASS}`
        ) as HTMLElement
        const positionClasses = [
            `${DROPDOWN_CONTENT_CLASS}--left`,
            `${DROPDOWN_CONTENT_CLASS}--center`,
            `${DROPDOWN_CONTENT_CLASS}--right`,
        ]
        let dropdownLabelClicked = false

        function positionDropdown() {
            const screenWidth = window.innerWidth
            if (window.matchMedia('(max-width: 1199)').matches) return

            dropdown.classList.add(DROPDOWN_ENABLE_HOVER_CLASS)

            const contentWidth = dropdownContent.clientWidth
            const labelRect = dropdownLabel.getBoundingClientRect()
            const labelPosition = {
                left: labelRect.left,
                right: labelRect.right,
            }
            const labelMiddle =
                labelPosition.left +
                (labelPosition.right - labelPosition.left) / 2
            let isRightCutOff = labelPosition.left + contentWidth > screenWidth
            let willRightCutOffIfCenter =
                labelMiddle + contentWidth / 2 > screenWidth
            let willLeftCutOff = labelPosition.right - contentWidth < 0
            let contentLocation: 'left' | 'center' | 'right' = 'right'

            if (isRightCutOff) {
                if (willRightCutOffIfCenter && !willLeftCutOff) {
                    contentLocation = 'left'
                } else {
                    contentLocation = 'center'
                }
            }
            dropdownContent.classList.add(
                `${DROPDOWN_CONTENT_CLASS}--${contentLocation}`
            )

            navigationDropdownsWrapper.classList.remove('overflow-hidden')
        }
        function resetPositions() {
            navigationDropdownsWrapper.classList.add('overflow-hidden')

            if (window.matchMedia('(max-width: 1199)').matches) return

            dropdownLabel.blur()
            dropdown.classList.remove(DROPDOWN_ENABLE_HOVER_CLASS)

            setTimeout(() => {
                dropdownLabelClicked = false
                dropdownContent.classList.remove(...positionClasses)
            }, 100)
        }

        dropdown.addEventListener('mouseenter', () => {
            setTimeout(
                () => (dropdownLabelClicked ? false : positionDropdown()),
                5
            )
        })
        dropdown.addEventListener('mouseleave', resetPositions)

        dropdownLabel.addEventListener('click', () => {
            dropdownLabelClicked = true

            if (window.matchMedia('(min-width: 1200)').matches) {
                const dropdownHasPosition = positionClasses.some(
                    (positionClass) =>
                        dropdownContent.classList.contains(positionClass)
                )

                if (dropdownHasPosition) {
                    resetPositions()
                } else {
                    positionDropdown()
                }

                return
            }

            dropdown.classList.toggle(DROPDOWN_OPEN_CLASS)
        })
        dropdownLabel.addEventListener('focus', () => closeOpenDropdowns())
        dropdownBack.addEventListener('click', () => dropdownLabel.focus())
    })

    navigationToggle.addEventListener('click', () => {
        navigation.classList.toggle(NAVIGATION_OPEN_CLASS)
        document.body.classList.toggle(BODY_SCROLL_LOCK_CLASS)

        setTimeout(() => closeOpenDropdowns(), 150)
    })

    document.addEventListener('keyup', (e) => {
        if (e.code === 'Escape') {
            let anyDropdownsOpen = [...navigationDropdowns].some((dropdown) =>
                dropdown.classList.contains(DROPDOWN_OPEN_CLASS)
            )

            if (anyDropdownsOpen) {
                navigation
                    .querySelector(`.${DROPDOWN_OPEN_CLASS}`)
                    ?.classList.remove(DROPDOWN_OPEN_CLASS)
            } else {
                navigation.classList.remove(NAVIGATION_OPEN_CLASS)
            }
        }
    })
}

if (stickyButtonWrapper) {
    let scrollingTimer

    function handleScroll() {
        stickyButtonWrapper.classList.remove('hide-sticky')
        clearTimeout(scrollingTimer)
        scrollingTimer = setTimeout(() => {
            stickyButtonWrapper.classList.add('hide-sticky')
        }, 3000)
    }

    setTimeout(() => window.addEventListener('scroll', handleScroll), 1000)
}
