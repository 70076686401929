const DISPOSAL_TYPES_CLASS = 'disposal-types'
const DISPOSAL_TYPE_ADVICE_CHECKBOX = 'disposal-type__advice-checkbox'
const DISPOSAL_TYPE_WRAPPER = 'disposal-type__wrapper'

const disposalType = document.querySelector<HTMLElement>(
    `.${DISPOSAL_TYPES_CLASS}`
)
const disposalTypeAdviceCheckbox =
    disposalType?.querySelector<HTMLInputElement>(
        `.${DISPOSAL_TYPE_ADVICE_CHECKBOX}`
    )

if (disposalType && disposalTypeAdviceCheckbox) {
    const disposalTypeWrappers = disposalType?.querySelectorAll<HTMLElement>(
        `.${DISPOSAL_TYPE_WRAPPER}`
    )

    disposalTypeWrappers.forEach((disposalTypeWrapper) => {
        const disposalTypeCheckbox =
            disposalTypeWrapper.querySelector<HTMLInputElement>(
                'input'
            ) as HTMLInputElement

        disposalTypeCheckbox.addEventListener('change', () => {
            if (disposalTypeCheckbox.checked) {
                disposalTypeAdviceCheckbox.checked = false
            }
        })
    })

    disposalTypeAdviceCheckbox.addEventListener('change', () => {
        if (disposalTypeAdviceCheckbox.checked) {
            disposalTypeWrappers.forEach((disposalTypeWrapper) => {
                const disposalTypeCheckbox =
                    disposalTypeWrapper.querySelector<HTMLInputElement>(
                        'input'
                    ) as HTMLInputElement

                disposalTypeCheckbox.checked = false
            })
        }
    })
}
