const shareButtons =
    document.querySelectorAll<HTMLButtonElement>('.share-button')

shareButtons.forEach((shareButton) => {
    shareButton.addEventListener('click', () => {
        const title = shareButton.dataset.pageTitle || document.title
        const url = shareButton.dataset.pageUrl || window.location.href

        if (navigator.share) {
            navigator.share({
                title,
                url,
            })
        } else {
            navigator.clipboard.writeText(url)
            shareButton.classList.add('copied')
            setTimeout(() => {
                shareButton.classList.remove('copied')
            }, 2500)
        }
    })
})
