import Swiper, {Autoplay} from 'swiper'

import 'swiper/css'

new Swiper('.brand-slider', {
    modules: [Autoplay],
    centeredSlides: true,
    slidesPerView: 3,
    spaceBetween: 32,
    loop: true,
    breakpoints: {
        480: {
            slidesPerView: 3,
            spaceBetween: 40,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 80,
        },
        1024: {
            // lg
            slidesPerView: 5,
            spaceBetween: 120,
        },
        1440: {
            // 2xl
            slidesPerView: 5,
            spaceBetween: 160,
        },
    },
    autoplay: {
        disableOnInteraction: false,
    },
})
