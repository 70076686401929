const ids = ['gclid', 'msclkid']

ids.forEach(id => {
    const cookieId = 'msn' + (id.charAt(0).toUpperCase() + id.slice(1))

    const adIdFields = document.querySelectorAll(`[name="fields[${id}]"]`)
    let adId = new URLSearchParams(window.location.search).get(id)

    window.addEventListener('CookiebotOnAccept', () => {
        if (adId) {
            saveAdId(adId)
        } else {
            adId = getAdIdFromStorage()
        }

        if (adId === null) return
        // @ts-ignore
        if (!Cookiebot.consent.marketing) deleteSavedAdId()

        adIdFields.forEach((adIdField) => {
            (adIdField as HTMLInputElement).value = adId as string
        })
    })
    window.addEventListener('CookiebotOnDecline', () => deleteSavedAdId())

    function getAdIdFromStorage(): string | null {
        const adIdWithDate = localStorage.getItem(cookieId)

        if (adIdWithDate === null) return null

        const { adId, date } = JSON.parse(adIdWithDate)
        const adIdDate = new Date(date)
        const currentDate = new Date(getCurrentDateStamp())
        const millisecondDifference = currentDate.getTime() - adIdDate.getTime()
        const maxLifetimeDays = 90
        const millisecondLimit = maxLifetimeDays * 24 * 60 * 60 * 1000

        if (millisecondDifference >= millisecondLimit) {
            localStorage.removeItem(cookieId)

            return null
        }

        return adId
    }
    function saveAdId(adId): void {
        const adIdWithDate = {
            adId,
            date: getCurrentDateStamp(),
        }

        localStorage.setItem(cookieId, JSON.stringify(adIdWithDate))
    }
    function getCurrentDateStamp(): string {
        return new Date().toISOString().split('T')[0]
    }
    function deleteSavedAdId(): void {
        localStorage.removeItem(cookieId)

        adIdFields.forEach((adIdField) => {
            (adIdField as HTMLInputElement).value = ''
        })
    }
})

function FbCookie() {
    const cookieId = '_fbc';
    const adIdFields = document.querySelectorAll(`[name="fields[fbclid]"]`);

    window.addEventListener('CookiebotOnAccept', () => {
        const value = getCookieByName(cookieId);

        if (value === '') return;
        adIdFields.forEach((adIdField) => {
            (adIdField as HTMLInputElement).value = value;
        });
    });

    function getCookieByName(name: string): string {
        const cookies = document.cookie.split(';');
        const cookie = cookies.find((cookie) => cookie.includes(name));
        return cookie ? cookie.split('=')[1] : '';
    }
}

FbCookie();
