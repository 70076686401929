// media queries
export const prm = window.matchMedia('(prefers-reduced-motion: reduce)')

// function to check if a media query matches
export function mq(mq) {
    if (mq.matches) {
        return true
    } else {
        return false
    }
}
