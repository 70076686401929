// Checks for reduced motion preference
import {prm, mq} from '../_utilities/mediaQueries'
const prefRmCls = 'prefers-reduced-motion'
prm.addEventListener('change', checkRm)

function checkRm() {
    mq(prm)
        ? document.body.classList.add(prefRmCls)
        : document.body.classList.remove(prefRmCls)
}

checkRm()
