import Details from '../modules/details';

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.faq__item').forEach((faqItem) => {
        new Details(faqItem as HTMLDetailsElement);
    });

    const faqData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
    };

    document.querySelectorAll('.faq__item').forEach((faqElement) => {
        const faq = faqElement as HTMLElement;

        if (faq.dataset.shownInStructuredData === 'true') {
            faqData.mainEntity.push({
                "@type": "Question",
                "name": faq.querySelector('.faq__question')?.textContent || '',
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": faq.querySelector('.faq__answer')?.innerHTML || '',
                }
            });
        }
    });

    if (faqData.mainEntity.length > 0) {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.textContent = JSON.stringify(faqData);
        document.head.appendChild(script);
    }
});
