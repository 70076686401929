export default class Details {
    el: HTMLDetailsElement
    header: HTMLElement
    content: HTMLElement
    animation: null | Animation
    isClosing: boolean = false
    isExpanding: boolean = false

    constructor(el: HTMLDetailsElement) {
        this.el = el
        this.header = el.querySelector('.details__header') as HTMLElement
        this.content = el.querySelector('.details__content') as HTMLElement
        this.animation = null
        this.header.addEventListener('click', (e) => this.onClick(e))
    }

    onClick(e: Event) {
        e.preventDefault()

        this.el.style.overflow = 'hidden'

        if (this.isClosing || !this.el.open) {
            this.open()
        } else if (this.isExpanding || this.el.open) {
            this.shrink()
        }
    }

    shrink() {
        this.isClosing = true

        const startHeight = `${this.el.offsetHeight}px`
        const endHeight = `${this.header.offsetHeight}px`

        if (this.animation) {
            this.animation.cancel()
        }

        this.animation = this.el.animate(
            {
                height: [startHeight, endHeight],
            },
            {
                duration: 150,
                easing: 'ease-out',
            }
        )

        this.animation.onfinish = () => this.onAnimationFinish(false)
        this.animation.oncancel = () => (this.isClosing = false)
    }

    open() {
        this.el.style.height = `${this.el.offsetHeight}px`
        this.el.open = true

        window.requestAnimationFrame(() => this.expand())
    }

    expand() {
        this.isExpanding = true

        const startHeight = `${this.el.offsetHeight}px`
        const endHeight = `${
            this.header.offsetHeight + this.content.offsetHeight
        }px`

        if (this.animation) {
            this.animation.cancel()
        }

        this.animation = this.el.animate(
            {
                // Set the keyframes from the startHeight to endHeight
                height: [startHeight, endHeight],
            },
            {
                duration: 150,
                easing: 'ease-out',
            }
        )

        this.animation.onfinish = () => this.onAnimationFinish(true)
        this.animation.oncancel = () => (this.isExpanding = false)
    }

    onAnimationFinish(open: boolean) {
        this.el.open = open
        this.animation = null
        this.isClosing = false
        this.isExpanding = false
        this.el.style.height = this.el.style.overflow = ''
    }
}
