const form = document.getElementById('nav-search') as HTMLElement

if (form) {
    const input = form.querySelector('.search-form__input') as HTMLElement
    const openSearchBtn = document.getElementById('open-search') as HTMLElement

    if (input && form && openSearchBtn) {
        const navSearchActive = 'nav-search-active'

        // click on open search button
        openSearchBtn.addEventListener('click', () => {
            openSearch()
            input.focus()
        })

        // on search form focus
        form.addEventListener('focusin', () => {
            openSearch()
        })

        // on escape key, close search
        document.addEventListener('keyup', (e) => {
            if (e.key == 'Escape') {
                closeSearch()
                input.blur() // make sure input loses focus
            }
        })

        // on form focus out
        form.addEventListener('focusout', () => {
            closeSearch()
        })

        // open search
        function openSearch() {
            openSearchBtn.setAttribute('aria-expanded', 'true')
            document.body.classList.add(navSearchActive)
        }

        // close search
        function closeSearch() {
            openSearchBtn.setAttribute('aria-expanded', 'false')
            document.body.classList.remove(navSearchActive)
        }
    }
}
