import {v4 as uuidv4} from 'uuid'

export const QUOTATION_CLASSES = [
    'quotation--default',
    'quotation--arhive-destruction',
]

QUOTATION_CLASSES.forEach((quotationClass) => {
    const quotation = document.querySelector(`.${quotationClass}`)

    if (!quotation) return
    if (!localStorage.getItem('formUuid')) {
        localStorage.setItem('formUuid', uuidv4())
    }

    function isLessThanOneDayAgo(date) {
        const dayInMs = 24 * 60 * 60 * 1000
        const timeDayAgo = Date.now() - dayInMs

        return date > timeDayAgo
    }

    const urlHasQueries = !!window.location.href.split('?')[1]

    if (!urlHasQueries) {
        const lastQuotationUrl = localStorage.getItem(quotationClass)

        if (!window.location.href.includes('?') && lastQuotationUrl) {
            const {url, timestamp} = JSON.parse(lastQuotationUrl)

            if (isLessThanOneDayAgo(timestamp)) {
                window.location.replace(url)
            }
        } else {
            localStorage.removeItem(quotationClass)
        }
    }

    // Saving happens in templates/_sprig/quotation/quotation.twig
})
