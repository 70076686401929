import Swiper, {Autoplay} from 'swiper'

import 'swiper/css'

new Swiper('.home-slider', {
    modules: [Autoplay],
    centeredSlides: true,
    slidesPerView: 1,
    speed: 800,
    loop: true,
    autoplay: {
        disableOnInteraction: false,
        delay: 8000,
    },
})
