const DISPOSAL_CONTAINERS_CLASS = 'disposal-containers'

const DISPOSAL_CONTAINER_ITEM_WRAPPER_CLASS =
    'disposal-containers__item-wrapper'
const DISPOSAL_CONTAINER_ITEM_WRAPPER_SELECTED_CLASS = `step__card--selected`
const DISPOSAL_CONTAINER_ITEM_WRAPPER_HIDDEN_CLASS = `${DISPOSAL_CONTAINER_ITEM_WRAPPER_CLASS}--hidden`
const DISPOSAL_CONTAINER_ITEM_WRAPPER_OVERFLOW_CLASS = `${DISPOSAL_CONTAINER_ITEM_WRAPPER_CLASS}--overflow`

const DISPOSAL_OVERFLOW_BUTTON_CLASS = 'disposal-containers__overflow'
const DISPOSAL_OVERFLOW_BUTTON_OPEN_CLASS = `${DISPOSAL_OVERFLOW_BUTTON_CLASS}--open`

const DISPOSAL_ADVICE_OPTION_CLASS = 'disposal-containers__advice'
const DISPOSAL_ADVICE_CHECKBOX_CLASS = 'disposal-containers__advice-checkbox'

const disposalContainerWrappers = document.querySelectorAll(
    `.${DISPOSAL_CONTAINERS_CLASS}`
)

disposalContainerWrappers.forEach((disposalContainerWrapper) => {
    const disposalItemWrappers = disposalContainerWrapper.querySelectorAll(
        `.${DISPOSAL_CONTAINER_ITEM_WRAPPER_CLASS}`
    )
    const adviceOption = disposalContainerWrapper.querySelector(
        `.${DISPOSAL_ADVICE_OPTION_CLASS}`
    )
    const adviceCheckbox = disposalContainerWrapper.querySelector(
        `.${DISPOSAL_ADVICE_CHECKBOX_CLASS}`
    ) as HTMLFormElement | null

    disposalItemWrappers.forEach((disposalItemWrapper) => {
        const disposalContainersGrid =
            disposalItemWrapper.parentElement as HTMLElement
        const maxContainersAmount = disposalContainersGrid.dataset.maxContainers
            ? Number(disposalContainersGrid.dataset.maxContainers)
            : false
        const containerInput = disposalItemWrapper.querySelector(
            '.disposal-containers__input'
        ) as HTMLFormElement
        const minusButton = disposalItemWrapper.querySelector(
            '.disposal-containers__input-button--minus'
        ) as HTMLButtonElement
        const plusButton = disposalItemWrapper.querySelector(
            '.disposal-containers__input-button--plus'
        ) as HTMLButtonElement

        function updateContainerWrapper(currentValue) {
            if (currentValue < 0) {
                currentValue = 0
            } else if (currentValue > 0 && adviceCheckbox?.checked) {
                adviceCheckbox.checked = false
            }

            if (maxContainersAmount) {
                const totalAmountSelected = [...disposalItemWrappers].reduce(
                    (total, disposalItemWrapper) => {
                        const containerInput =
                            disposalItemWrapper.querySelector(
                                '.disposal-containers__input'
                            ) as HTMLFormElement
                        const amountSelected = Number(containerInput.value)

                        return (total += amountSelected)
                    },
                    0
                )

                if (
                    totalAmountSelected === maxContainersAmount &&
                    containerInput.value < currentValue
                ) {
                    return
                }
            }

            containerInput.value = currentValue

            if (currentValue) {
                disposalItemWrapper.classList.add(
                    DISPOSAL_CONTAINER_ITEM_WRAPPER_SELECTED_CLASS
                )
                minusButton.disabled = false
            } else {
                disposalItemWrapper.classList.remove(
                    DISPOSAL_CONTAINER_ITEM_WRAPPER_SELECTED_CLASS
                )
                minusButton.disabled = true
            }
        }

        minusButton.addEventListener('click', () => {
            updateContainerWrapper(Number(containerInput.value) - 1)
        })
        plusButton.addEventListener('click', () => {
            updateContainerWrapper(Number(containerInput.value) + 1)
        })
        disposalItemWrapper.addEventListener('click', (e) => {
            if (
                [containerInput, minusButton, plusButton].includes(
                    e.target as any
                )
            ) {
                return
            }

            const currentValue = Number(containerInput.value)

            if (currentValue === 0) {
                plusButton.click()
            } else if (currentValue === 1) {
                minusButton.click()
            }
        })
    })

    const overflowButton = disposalContainerWrapper.querySelector(
        `.${DISPOSAL_OVERFLOW_BUTTON_CLASS}`
    )

    if (overflowButton) {
        overflowButton.addEventListener('click', () => {
            const hiddenDisposalItemWrappers =
                disposalContainerWrapper.querySelectorAll(
                    `.${DISPOSAL_CONTAINER_ITEM_WRAPPER_OVERFLOW_CLASS}`
                )

            hiddenDisposalItemWrappers.forEach((overflownItem) =>
                overflownItem.classList.toggle(
                    DISPOSAL_CONTAINER_ITEM_WRAPPER_HIDDEN_CLASS
                )
            )

            overflowButton.classList.toggle(DISPOSAL_OVERFLOW_BUTTON_OPEN_CLASS)
        })
    }

    if (adviceOption) {
        adviceOption.addEventListener('click', () => {
            setTimeout(() => {
                const adviceCheckboxChecked = adviceCheckbox.checked

                if (adviceCheckboxChecked) {
                    disposalItemWrappers.forEach((disposalItemWrapper) => {
                        const containerInput =
                            disposalItemWrapper.querySelector(
                                '.disposal-containers__input'
                            ) as HTMLFormElement
                        const minusButton = disposalItemWrapper.querySelector(
                            '.disposal-containers__input-button--minus'
                        ) as HTMLButtonElement

                        containerInput.value = 0

                        disposalItemWrapper.classList.remove(
                            DISPOSAL_CONTAINER_ITEM_WRAPPER_SELECTED_CLASS
                        )
                        minusButton.disabled = true
                    })
                }
            }, 10)
        })
    }
})
