import {prm, mq} from '../_utilities/mediaQueries'
interface videoConfig {
    blockId: string
    videoId: string
    videoWrapper: Element
}

const videoWrappers = document.querySelectorAll('.video__wrapper')
const videoConfigs: videoConfig[] = []

videoWrappers.forEach((videoWrapper, index) => {
    const blockId = (videoWrapper as HTMLElement).dataset.blockId as string
    const videoId = (videoWrapper as HTMLElement).dataset.videoId as string

    videoConfigs.push({
        blockId,
        videoId,
        videoWrapper,
    })

    const placeholderButton = videoWrapper.querySelector(
        '.video__placeholder-button'
    ) as Element

    placeholderButton.addEventListener('click', () => {
        // @ts-ignore
        Cookiebot.show()
    })
})

if (videoWrappers.length) {
    let scriptLoaded = false

    window.addEventListener('CookiebotOnAccept', () => {
        // @ts-ignore
        if (!Cookiebot.consent.marketing) {
            removeVideos()

            return
        }

        if (!scriptLoaded) {
            const tag = document.createElement('script')
            const firstScriptTag = document.getElementsByTagName(
                'script'
            )[0] as HTMLScriptElement

            tag.src = 'https://www.youtube.com/iframe_api'

            firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)
            ;(window as any).onYouTubePlayerAPIReady = function () {
                scriptLoaded = true

                createVideosFromConfigs()
            }
        } else {
            createVideosFromConfigs()
        }
    })

    window.addEventListener('CookiebotOnDecline', () => removeVideos())
}

function createVideosFromConfigs() {
    videoConfigs.forEach((videoConfig) => createPlayer(videoConfig))
}

function createPlayer(videoConfig: videoConfig) {
    // @ts-ignore
    return new YT.Player(videoConfig.blockId, {
        width: 1280,
        height: 720,
        playerVars: {autoplay: mq(prm) ? 0 : 1, playsinline: 1, rel: 0},
        events: {
            onReady: (event) => {
                event.target.mute()
            },
        },
        videoId: videoConfig.videoId,
    })
}

function removeVideos() {
    videoConfigs.forEach((videoConfig) => {
        const noVideoDiv = document.createElement('div')

        noVideoDiv.id = videoConfig.blockId

        const videoIframe = videoConfig.videoWrapper.querySelector('iframe')

        videoIframe?.replaceWith(noVideoDiv)
    })
}
