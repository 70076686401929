const ctas = document.querySelectorAll('.cta-big')
const THUMBNAIL_CLASS = 'cta-big__thumbnail'
const THUMBNAIL_CURRENT_CLASS = `${THUMBNAIL_CLASS}--current`
const USP_CLASS = 'cta-big__usp'
const USP_CURRENT_CLASS = `${USP_CLASS}--current`

ctas.forEach((cta) => {
    const thumbnails = cta.querySelectorAll(
        `.${THUMBNAIL_CLASS}`
    ) as NodeListOf<HTMLElement>
    const usps = cta.querySelectorAll(
        `.${USP_CLASS}`
    ) as NodeListOf<HTMLElement>

    function removeCurrentClasses() {
        thumbnails.forEach((thumbnail) =>
            thumbnail.classList.remove(THUMBNAIL_CURRENT_CLASS)
        )
        usps.forEach((usp) => usp.classList.remove(USP_CURRENT_CLASS))
    }

    usps.forEach((usp) => {
        usp.addEventListener('mouseenter', () => {
            const uspImageId = usp.dataset.imageId

            removeCurrentClasses()

            const thumbnailForUSP = [...thumbnails].find(
                (thumbnail) => thumbnail.dataset.imageId === uspImageId
            ) as HTMLElement

            usp.classList.add(USP_CURRENT_CLASS)
            thumbnailForUSP.classList.add(THUMBNAIL_CURRENT_CLASS)
        })
    })
})
