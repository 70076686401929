import Swiper, {Navigation, Scrollbar} from 'swiper'

import 'swiper/css'

new Swiper('.reviews-slider', {
    modules: [Navigation, Scrollbar],
    centeredSlides: true,
    slidesPerView: 1.25,
    spaceBetween: 16,
    breakpoints: {
        480: {
            slidesPerView: 1.5,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 2.25,
            spaceBetween: 16,
        },
        1024: {
            // lg
            slidesPerView: 2.75,
            spaceBetween: 32,
        },
        1600: {
            // 3xl
            slidesPerView: 3.5,
            spaceBetween: 32,
        },
        1800: {
            // Custom screen size
            slidesPerView: 4,
            spaceBetween: 32,
        },
    },
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        hide: false,
        dragClass: 'reviews-slider__scrollbar-thumb',
    },
})
