const keyVisualGraphic: HTMLElement = document.querySelector(
    '.keyvisual__graphic'
) as HTMLElement

if (keyVisualGraphic) {
    const updateHeight = () => {
        if (document.body.offsetWidth < 1024) {
            keyVisualGraphic.style.height = ''
        } else {
            keyVisualGraphic.style.height =
                Math.floor(
                    document.body.offsetHeight - keyVisualGraphic.offsetTop - 1
                ) + 'px'
        }
    }

    const observer = new ResizeObserver(() => {
        updateHeight()
        return
    })

    observer.observe(document.body)
    updateHeight()
}
